<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">LISTADO DE INSCRITOS AL NEWSLETTER</h1>
    <div class="container-actions-top-table">
      <div class="container-searcher">
        <b-input-group size="sm" class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input type="search" placeholder="Buscador" v-model.trim="textSearch"></b-form-input>
        </b-input-group>
      </div>
      <div class="container-add-new">
        <b-button variant="primary" @click="descargarFichero()">Descargar</b-button>
      </div>
    </div>
    <div class="container-table">
      <b-table striped :stacked="windowWidth < 600" hover :items="itemsFiltered" :fields="fields" :busy="loadingData"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sort-icon-left responsive="sm" :per-page="perPage"
        :current-page="currentPage" :filter="textSearch.toLowerCase()">
        <!-- <template #cell(nombre)="data">
            <span>{{ data.item.nombres }} {{ data.item.apellidos }}</span>
          </template> -->
        <template #cell(actions)="data">
          <!-- <b-icon
              icon="pencil-square"
              variant="warning"
              @click="editRegister({ ...data.item })"
              scale="1.5"
              style="margin: 0px 10px; cursor: pointer"
              v-b-tooltip.hover
              title="Editar"
            ></b-icon> -->
          <b-icon icon="x-circle" variant="danger" @click="deleteItem({ ...data.item })" scale="1.5"
            style="margin: 0px 10px; cursor: pointer" v-b-tooltip.hover title="Eliminar"></b-icon>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Cargando Datos...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <div class="container-paginator">
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
      <span style="margin-left: 15px">Items por página</span>
      <b-form-select v-model="perPage" :options="optionsPerPage" style="width: 80px; margin-left: 10px"></b-form-select>
    </div>
  </div>
</template>
  
<script>
import NewsletterService from "@/services/newsletter.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "NewsLetterListAdmin",
  data() {
    return {
      usersService: null,
      sortBy: "identificacion",
      fields: [
        { key: "email", sortable: true },
        { key: "idioma", sortable: true },
        { key: "actions", sortable: false },
      ],
      from: 'newsletterlist',
      items: [],
      itemsFiltered: [],
      windowWidth: window.innerWidth,
      loadingData: false,
      perPage: 10,
      currentPage: 1,
      optionsPerPage: [5, 10, 25, 50, 100],
      textSearch: "",
      itemSeleted: null,
      sortDesc: false,
    };
  },
  created() {
    this.newsletterService = new NewsletterService();
  },
  mounted() {
    this.getItemsForList();
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    EventBus.$on("RESPONSE_MODAL_QUESTION", (payLoad) => {
      if (payLoad.from == this.from) {
        switch (payLoad.action) {
          case "delete":
            this.ejecuteDelete();
            break;
          default:
            break;
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    descargarFichero() {
      let data = this.itemsFiltered.map((item) => {
        return {
          email: item.email,
          idioma: item.idioma,
        };
      });
      const stringDate= this.$moment().format('DD-MM-YYYY-HH-mm-ss')
      this.downloadCSV({ data: data, filename: `newsletter-${stringDate}.csv` });
    },
    downloadCSV({ data, filename }) {
      let csv = this.convertArrayOfObjectsToCSV({
        data: data,
      });
      if (csv == null) return;
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }
      const dataFile = encodeURI(csv);
      const link = document.createElement("a");
      link.setAttribute("href", dataFile);
      link.setAttribute("download", filename);
      link.click();
    },
    convertArrayOfObjectsToCSV({ data }) {
      let result, ctr, keys, columnDelimiter, lineDelimiter, dataCSV;
      dataCSV = data || null;
      if (dataCSV == null || !dataCSV.length) {
        return null;
      }
      columnDelimiter = ";";
      lineDelimiter = "\n";
      keys = Object.keys(dataCSV[0]);
      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      dataCSV.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    deleteItem(item) {
      this.itemSeleted = { ...item };
      EventBus.$emit("SHOW_MODAL_QUESTION", {
        titleModal: this.$t('messages.elimination'),
        messageModal: "¿Seguro desea eliminar el registro?",
        action: "delete",
        from: this.from,
      });
    },
    ejecuteDelete() {
      if (!this.itemSeleted) return false
      //sustituir el servicio
      this.newsletterService
        .delete(this.itemSeleted.id)
        .then((result) => {
          if (result.data.status == 200) {
            this.items = this.items.filter((item) => {
              return item.id != this.itemSeleted.id;
            });
            this.itemsFiltered = this.itemsFiltered.filter((item) => {
              return item.id != this.itemSeleted.id;
            });
            this.itemSeleted = null;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro eliminado satisfactoriamente",
            });
          }
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al eliminar registro",
          });
        });
    },
    getItemsForList() {
      this.loadingData = true;
      this.newsletterService
        .getAll()
        .then((result) => {
          this.items = [...result.data.data];
          this.itemsFiltered = [...result.data.data];
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar items de la lista",
          });
        });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
}

.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}

.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}

.container-actions-top-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}

@media (max-width: 470px) {
  .container-paginator {
    flex-direction: column;
    align-items: center;
  }
}
</style>
  