import axios from "axios";

export default class NewsletterService {
  getAll() {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "newsletter");
  }
  suscribe(data) {
    return axios.post(process.env.VUE_APP_API_PUBLIC + "newsletter", data);
  }
  unsuscribe(id) {
    return axios.get(
      process.env.VUE_APP_API_PUBLIC + "newsletter/unsuscribe/" + id
    );
  }
  unsuscribeMailEncoded(emailencoded) {
    return axios.get(
      process.env.VUE_APP_API_PUBLIC + "newsletter/unsuscribe/m/" + emailencoded
    );
  }
  unsuscribeByEmail(email) {
    return axios.get(
      process.env.VUE_APP_API_PUBLIC + "newsletter/unsuscribe/email/" + email
    );
  }
  delete(id) {
    return axios.get(
      process.env.VUE_APP_API_PUBLIC + "newsletter/admin/unsuscribe/" + id
    );
  }
  sendNewsletter(id) {
    return axios.get(
      process.env.VUE_APP_API_PUBLIC + "newsletter/sendmails/" + id
    );
  }
  getFileOfEmails() {
    //add header Allow-Control-Allow-Origin: *
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "text/csv"
    };
    return axios.get(process.env.VUE_APP_API_PUBLIC + "newsletter/email", {
      headers: headers,
      responseType: "blob"
    });    
  }
}
